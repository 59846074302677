<template>
  <div class="project-main-container">
    <div class="first-info-container">
      <img class="first-img" :src="firstImgUrl">
      <div class="first-txt-container">
        <div class="first-txt-title">{{firstInfo.title}}</div>
        <div class="first-txt-time">{{firstInfo.createTime}}</div>
        <div class="first-txt-desc">{{firstInfo.subtitle}}</div>
        <div class="know-more-btn" @click="clickInfo">
          <div style="font-size:8px;cursor: pointer">
            了解更多
          </div>
        </div>
      </div>
    </div>
    <div
      class="project-other-container"
      v-for="(item,index) in projectInfos"
      :key="index"
      :data-id="index"
      @click="clickInfo"
    >
      <img class="project-info-img" :src="item.parkNewsImgList[0].url">
      <div class="project-other-txt-container">
        <div class="first-txt-title">{{item.title}}</div>
        <div class="first-txt-time">{{item.createTime}}</div>
        <div class="first-txt-desc" style="margin-top:0.36rem">{{item.subtitle}}</div>
      </div>
    </div>
    <pager :total="page" @transfer="setInfos" :type=3></pager>
  </div>
</template>

<script>
import UtilPaths from '../../utils/UtilsPath'
import axios from 'axios'
import pager from '../Pager'
export default {
  data () {
    return {
      firstInfo: {},
      firstImgUrl: '',
      projectInfos: [],
      page: 0,
      currentPage: 1
    }
  },
  async created () {
    let tempIndex = parseInt(this.$route.params.index)
    tempIndex = JSON.stringify(tempIndex)
    if (tempIndex && tempIndex !== 'null') {
      sessionStorage.setItem('UNProjectIndex', tempIndex)
    } else {
      tempIndex = sessionStorage.getItem('UNProjectIndex')
    }
    sessionStorage.setItem('UNIndex', 1)
    let tempData = await axios.get(UtilPaths.getProjectInfo + '/' + tempIndex + '/' + 4)
    let tempInfos = tempData.data
    if (tempInfos.records.length !== 1) {
      let tempOtherInfos = []
      this.$data.firstInfo = tempInfos.records[0]
      this.$data.firstImgUrl = tempInfos.records[0].parkNewsImgList[0].url
      this.$data.page = tempInfos.pages
      this.$data.currentPage = tempInfos.current
      for (let i = 1; i < tempInfos.records.length; i++) {
        tempOtherInfos[i - 1] = tempInfos.records[i]
      }
      this.$data.projectInfos = tempOtherInfos
    } else {
      this.$data.firstInfo = tempInfos.records[0]
      this.$data.firstImgUrl = tempInfos.records[0].parkNewsImgList[0].url
      this.$data.page = tempInfos.pages
    }
  },
  methods: {
    clickInfo (e) {
      if (e.srcElement.innerText === '了解更多>>') {
        this.$router.push({ name: 'UNMoreProject', params: { index: 0, currentpage: this.$data.currentPage, info: this.$data.firstInfo } })
      } else {
        let temp = parseInt(e.currentTarget.dataset.id)
        this.$router.push({ name: 'UNMoreProject', params: { index: temp + 1, currentpage: this.$data.currentPage, info: this.$data.projectInfos[temp] } })
      }
    },
    setInfos (item) {
      if (item.length === 1) {
        this.$data.firstInfo = item[0]
        this.$data.projectInfos = []
      } else {
        let tempOtherInfos = []
        for (let i = 1; i < item.length; i++) {
          tempOtherInfos[i - 1] = item[i]
        }
        this.$data.firstInfo = item[0]
        this.$data.projectInfos = tempOtherInfos
      }
    }
  },
  components: {
    pager
  }
}
</script>

<style scoped>
.project-main-container{
  display: flex;
  margin-top: 2.14rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.first-info-container{
  display: flex;
  padding-bottom: 2.14rem;
  border-bottom: 1px #dfdddd solid;
}
.first-img{
  width: 16.25rem;
  height: 9.57rem;
}
.first-txt-container{
  display: flex;
  flex-direction: column;
  margin-left: 1.78rem;
  text-align: start;
  position: relative;
  width: 23rem;
}
.first-txt-title{
  font-size: 0.85rem;
  color: #333333;
  letter-spacing: 3.5px;
}
.first-txt-time{
  font-size: 0.5rem;
  color: #999999;
  letter-spacing: 3px;
}
.first-txt-desc{
  font-size: 0.64rem;
  color: #999999;
  letter-spacing: 3.5px;
  margin-top: 0.46rem;
}
.know-more-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #333333 solid;
  width: 3.57rem;
  height: 1.07rem;
  position: absolute;
  bottom: 0;
}
.project-other-container{
  height: 9.42rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  padding-top: 2.14rem;
  padding-bottom: 2.14rem;
  border-bottom: 1px #dfdddd solid;
  cursor: pointer;
}
.project-info-img{
  width: 8.6rem;
  height: 5.07rem;
}
.project-other-txt-container{
  width: 30.5rem;
  height: 5.07rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0.71rem;
}
</style>
