import { render, staticRenderFns } from "./UNProject.vue?vue&type=template&id=68bf0f20&scoped=true&"
import script from "./UNProject.vue?vue&type=script&lang=js&"
export * from "./UNProject.vue?vue&type=script&lang=js&"
import style0 from "./UNProject.vue?vue&type=style&index=0&id=68bf0f20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68bf0f20",
  null
  
)

export default component.exports