<template>
  <div class="pageContainer">
    <ul class="pagesInner">
      <div class="clickbtn">
        <div @click="prevOrNext(-1)" class="fa fa-chevron-left" aria-hidden="true">{{left}}
        </div>
      </div>
      <div class="page" v-for="(item, index) in pages" :class="{actived: item === currentPage}" :key="index" @click="select(item)">
        {{item}}
      </div>
      <div class="clickbtn">
        <div @click="prevOrNext(1)" class="fa fa-chevron-right" aria-hidden="true">
        {{right}}
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import UtilsPath from '../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      currentPage: 1,
      totalPage: 50,
      left: '上一页',
      right: '下一页'
    }
  },
  props: {
    total: Number,
    type: Number
  },
  // created () {
  //   console.log(window.pageYOffset)
  // },
  computed: {
    pages () {
      // const c = this.$data.currentPage
      const t = this.$props.total
      let temp = []
      for (let i = 0; i < t; i++) {
        temp[i] = i + 1
      }
      return temp // [1,2,3,4,5]
      // if (c <= 5) {
      //   return [1, 2, 3, 4, 5, 6, 7, 8, 9, '...', t]
      // } else if (c >= t - 4) {
      //   return [1, '...', t - 8, t - 7, t - 6, t - 5, t - 4, t - 3, t - 2, t - 1, t] // 第二种情况
      // } else {
      //   return [1, '...', c - 3, c - 2, c - 1, c, c + 1, c + 2, c + 3, '...', t] // 第三种情况
      // }
    }
  },
  methods: {
    async select (item) {
      if (item === this.$data.currentPage) return
      if (typeof item === 'string') return
      window.scrollTo(0, 0)
      if (this.$props.type === 1) {
        let tempData = await axios.get(UtilsPath.getBussinessDetails + '/' + this.$route.params.index + '/' + item + '/' + 3)
        this.$store.commit('setShowZhaoshangItems', { showZhaoshangItems: tempData.data.records })
        this.$emit('transfer', tempData.data.records)
        this.$data.currentPage = item
        // window.scrollTo(0, 678)
      } else if (this.$props.type === 2) {
        let tempData = await axios.get(UtilsPath.getUNInfo + '/' + item + '/' + 4)
        this.$store.commit('setUNInfoItems', { xinwenItems: tempData.data.records })
        this.$emit('transfer', tempData.data.records)
        this.$data.currentPage = item
      } else if (this.$props.type === 3) {
        let tempData = await axios.get(UtilsPath.getProjectInfo + '/' + item + '/' + 4)
        this.$store.commit('setUNProjectItems', { unProjectItems: tempData.data.records })
        this.$emit('transfer', tempData.data.records)
        this.$data.currentPage = item
      }
    },
    prevOrNext (n) {
      console.log(111)
      let temp = this.$data.currentPage + n
      if (temp < 1) {
        temp = 1
      } else if (temp > this.$props.total) {
        temp = this.$props.total
      }
      this.select(temp)
      this.$data.currentPage += n
      if (this.$data.currentPage < 1) {
        this.$data.currentPage = 1
      } else if (this.$data.currentPage > this.$props.total) {
        this.$data.currentPage = this.$props.total
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
.pageContainer{
  display: flex;
  justify-content: center;
}
.pagesInner{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.22rem;
}
.page{
  font-size: 0.85rem;
  border-radius: 0;
  padding: 0 1.39rem;
  cursor: pointer;
}
.clickbtn{
  width: 3.34rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdddd;
  border-radius: 5px;
}
.fa{
  font-size: 0.7rem;
  cursor: pointer;
}
.actived{
  color: #68d5d0;
  font-size: 1.2rem;
}
.clickbtn:hover{
  /* border: 1px #000 solid;
  border-radius: 50%; */
  font-size: 33px;
  font-weight: bold;
}
</style>
